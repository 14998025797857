
class Logger {
    private readonly console: Console;
    private enabled: boolean = false;

    constructor() {
        this.console = console;
        // enabled on the client side if the host is localhost
        if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
            this.enabled = true;
        } else {
            // enabled on the server side
            this.enabled = true;
        }

    }

    public log = (...payload: any[]) => {
        this.console.log(...payload);
    };

    public warn = (...payload: any[]) => {
        this.console.warn(...payload);
    };

    public error = (...payload: any[]) => {
        this.console.error(...payload);
    };
}

let logger: Logger = new Logger();
export const Log = (...payload: any[]) => {
    logger.log(...payload);
}

export const LogIf = (condition: boolean, ...payload: any[]) => {
    if (condition) {
        Log(...payload);
    }
}

export const LogInfo = (...payload: any[]) => {
    // log in blue
    console.log('\x1b[34m', ...payload, '\x1b[0m');
}

export const LogWarning = (...payload: any[]) => {
    // logger.warn(...payload);
    // log in yellow
    console.log('\x1b[33m', ...payload, '\x1b[0m');
}

export const LogWarningIf = (condition: boolean, ...payload: any[]) => {
    if (condition) {
        LogWarning(...payload);
    }
}

export const LogError = (...payload: any[]) => {
    // logger.error(...payload);
    // log in red
    console.log('\x1b[31m', ...payload, '\x1b[0m');
}
