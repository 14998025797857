export const simpleAsciiFold = (str: string) => {
    return str
        // replace &eacute; and other html entities with their ascii equivalent
        .replace(/&eacute;/gi, 'e')
        .replace(/&egrave;/gi, 'e')
        .replace(/&ecirc;/gi, 'e')
        .replace(/&euml;/gi, 'e')
        .replace(/&ccedil;/gi, 'c')
        .replace(/&agrave;/gi, 'a')
        .replace(/&aacute;/gi, 'a')
        .replace(/&acirc;/gi, 'a')
        .replace(/&auml;/gi, 'a')
        .replace(/&iuml;/gi, 'i')
        .replace(/&icirc;/gi, 'i')
        .replace(/&igrave;/gi, 'i')
        .replace(/&iacute;/gi, 'i')
        .replace(/&ouml;/gi, 'o')
        .replace(/&ocirc;/gi, 'o')
        .replace(/&ograve;/gi, 'o')
        .replace(/&oacute;/gi, 'o')
        .replace(/&ucirc;/gi, 'u')
        .replace(/&ugrave;/gi, 'u')
        .replace(/&uuml;/gi, 'u')
        .replace(/&uacute;/gi, 'u')
        .replace(/&ntilde;/gi, 'n')
        .replace(/&szlig;/gi, 'ß')
        .replace(/&amp;/gi, '&')
        .replace(/&quot;/gi, '"')
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>')
        .replace(/&apos;/gi, "'")
        .replace(/&cent;/gi, 'c')
        .replace(/&pound;/gi, '£')
        .replace(/&yen;/gi, '¥')
        .replace(/&euro;/gi, '€')
        .replace(/(<([^>]+)>)/gi, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/gi, '')
        // .replace(/ß/gi, 'ss')
        .replace(/[^a-zA-Z0-9]/gi, '');
}