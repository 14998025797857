import * as React from 'react';
import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';

import {cn} from '../utils';

const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-lg font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'bg-button text-buttonText hover:bg-button/90',
                wkDisabled: 'bg-wikotiOff text-buttonText',
                destructive:
                    'bg-wiko text-buttonDestructive-foreground hover:bg-buttonDestructive/90',
                outline:
                    'border border-button bg-background hover:bg-button hover:text-buttonText',
                secondary:
                    'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
            },
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-9 rounded-md px-3',
                lg: 'h-11 rounded-md px-8',
                icon: 'h-10 w-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({className, variant, size, asChild = false, isLoading = false, ...props}, ref) => {
        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                className={cn(buttonVariants({variant, size, className}))}
                ref={ref}
                {...props}
            >
                <div className="flex flex-col items-center justify-center">
                    {!isLoading && props.children}
                    {isLoading ? (
                        <>
                            {props.children}
                            <div
                                className="absolute loader-bar h-1 b-2 bg-gradient-to-r from-transparent to-transparent bg-buttonText animate-stripe"/>
                        </>

                    ) : null}

                </div>
            </Comp>
        );
    }
);
Button.displayName = 'Button';

export interface IconButtonProps
    extends ButtonProps {
    icon: JSX.Element;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
    ({icon, className, variant, size, asChild = false, isLoading = false, ...props}, ref) => {
        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                className={cn(buttonVariants({variant, size, className}))}
                ref={ref}
                {...props}
            >
                <div className="flex flex-col items-center">
                    <div className={'flex flex-row items-center'}>
                        <icon.type {...icon.props} className={icon.props.className ?? 'w-6 h-6'}/>
                        {props.children && <div className={'ms-2'}>
                            {props.children}
                        </div>}
                        {isLoading ? (
                            <div
                                className="absolute loader-bar w-full h-1 b-2 bg-gradient-to-r from-transparent to-transparent via-primaryText animate-stripe"/>
                        ) : null}
                    </div>

                </div>
            </Comp>
        );
    }
);
IconButton.displayName = 'IconButton';

interface ToggleButtonProps {
    LeftIcon: JSX.Element;
    RightIcon: JSX.Element;
    leftValue: string; // Adjust the type as needed
    rightValue: string; // Adjust the type as needed
    defaultValue: string; // Optional default value
    onClick: (value: string) => void; // Callback with the current state value
    className?: string;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
                                                       LeftIcon,
                                                       RightIcon,
                                                       leftValue,
                                                       rightValue,
                                                       onClick,
                                                       defaultValue,
                                                       className,
                                                   }) => {
    const [active, setActive] = React.useState<'left' | 'right'>(defaultValue === leftValue ? 'left' : 'right');

    const handleLeftClick = () => {
        setActive('left');
        onClick(leftValue);
    };

    const handleRightClick = () => {
        setActive('right');
        onClick(rightValue);
    };

    return (
        <div className={cn('inline-flex w-full', className)}>
            {/* Remove the whitespace between buttons */}
            <button
                className={cn(
                    active === 'left' ? 'bg-button rounded-l-md border-2 border-button' : 'bg-background border-2 border-button rounded-l-md',
                    'flex-1 p-1 transition-colors'
                )}
                onClick={handleLeftClick}
            >
                <LeftIcon.type {...LeftIcon.props} className={cn("w-6 h-6 mx-auto", active === 'left'? 'fill-buttonText': 'fill-foreground')}/>
            </button>
            {/* No space or newline here */}
            <button
                className={cn(
                    active === 'right' ? 'bg-button rounded-r-md border-2 border-button' : 'bg-background border-2 border-button rounded-r-md',
                    'flex-1 p-1 transition-colors'
                )}
                onClick={handleRightClick}
            >
                <RightIcon.type {...RightIcon.props} className={cn("w-6 h-6 mx-auto", active === 'right'? 'fill-buttonText': 'fill-foreground')}/>
            </button>
        </div>
    );
};

ToggleButton.displayName = 'ToggleButton';

export {Button, IconButton, ToggleButton, buttonVariants};
