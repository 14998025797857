'use client';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type {ReactNode} from 'react';

interface ToastProviderProps {
    children: ReactNode;
}

export const ClientToastProvider = ({children}: ToastProviderProps) => {
    return (
        <>
            {children}
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                pauseOnFocusLoss={false}
                draggable
                theme="light"/>
        </>
    );
};