'use client';

import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import {cn} from '../utils';


const Slider = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & { valuelabel?: JSX.Element }
>(({className, ...props}, ref) => (
    <SliderPrimitive.Root
        ref={ref}
        className={cn(
            'relative flex justify-center w-1 touch-none select-none',
            className
        )}
        {...props}
    >
        <SliderPrimitive.Track className="relative h-2 w-1 grow overflow-hidden rounded-full bg-background">
            <SliderPrimitive.Range className="absolute h-full bg-background"/>
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
            className="block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
            {props.valuelabel && props.orientation === 'vertical' && <div className={'-ml-28'}>
                {props.valuelabel}
            </div>}
        </SliderPrimitive.Thumb>
    </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export {Slider};
