export class Routing {
    static addSearchParam(url: string, key: string, value: string): string {
        const urlObj = new URL(url);
        urlObj.searchParams.set(key, value);
        return urlObj.toString();
    }

    static getSelectedLanguage(restaurant: { defaultLanguage: string }, lang?: string | undefined | null) {
        return lang ? lang : restaurant.defaultLanguage;
    }

    static getHash = () =>
        typeof window !== 'undefined'
            ? decodeURIComponent(window.location.hash.replace('#', ''))
            : 'undefined';
}