import { cn } from "../utils";

export type SeparatorProps = {
    className?: string;
    orientation?: 'horizontal' | 'vertical';
};
export const Separator = (props: SeparatorProps) => {
    const { className, orientation = 'horizontal' } = props;
    const separatorClassName = cn(
        // dashed border
        'border-b border-wikotiAction border-dashed',
        {
            'h-px w-full': orientation === 'horizontal',
            'h-full w-px': orientation === 'vertical',
        },
        className
    );
    return <div className={'flex flex-row items-center py-4'}>
        <div className={separatorClassName} />
    </div>;
};