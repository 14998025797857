export class QuantityFormatter {
    static inCl(quantity: number): [number, string] {
        const value = isNaN(quantity) ? 0 : quantity / 10;
        return [value, 'cl'];
    }

    static inMl(quantity?: number): [number | undefined, string] {
        return [quantity, 'ml'];
    }

    static inL(quantity: number): [number, string] {
        const value = isNaN(quantity) ? 0 : quantity / 100;
        return [value, 'l'];
    }

    static inGrams(quantity?: number): [number | undefined, string] {
        return [quantity, 'g'];
    }
}
