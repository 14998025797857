'use client';

import Select, { ActionMeta, OnChangeValue, Options } from 'react-select';
import { Responsive } from '@repo/utils';

export interface ComboBoxItem {
    value: string;
    label: string;
}

export interface ComboBoxProps {
    items: ComboBoxItem[];
    isMulti?: boolean;
    defaultValue?: ComboBoxItem[];
    placeholder: string;
    onItemsSelected?: (selectedItems: ComboBoxItem[]) => void;
    onItemSelected?: (selectedItem: ComboBoxItem) => void;
    noOptionsMessage: string;
    className?: string;
    menuIsOpen?: boolean;
    isOptionSelected?: (option: ComboBoxItem, selectValue: Options<ComboBoxItem>) => boolean;
}

export const ComboBox = (props: ComboBoxProps) => {
    const { items, placeholder, onItemsSelected, onItemSelected, noOptionsMessage, defaultValue, className, menuIsOpen, isMulti = true } = props;
    return <Select
        className={className}
        components={{
            DropdownIndicator: () => null, IndicatorSeparator: () => null
        }}
        styles={{
            control: (provided, state) => {
                return {
                    ...provided,
                    '&:hover': {
                        borderColor: 'var(--color-button)',
                    },
                    backgroundColor: 'var(--color-button)',
                    borderColor: 'var(--color-border)',
                    boxShadow: 'none',
                }
            },
            input: (provided, state) => {
                return {
                    ...provided,
                    color: 'var(--color-button-text)',
                }
            },
            placeholder: (provided, state) => ({
                ...provided,
                color: 'var(--color-button-text)',
                fontSize: Responsive.isMobileView() ? '1rem' : '1.5rem'
            }),
            clearIndicator: (provided, state) => ({
                ...provided,
                color: 'var(--color-button-text)'
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                color: 'var(--color-button-text)'
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                backgroundColor: 'var(--color-button-text)'
            }),
            menuList: (provided, state) => ({
                ...provided,
                backgroundColor: 'var(--color-button)',
                color: 'var(--color-button-text)',
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: 'var(--color-button)',
            }),
            menu: (provided, state) => ({
                ...provided,
                backgroundColor: 'var(--color-button)',
                color: 'var(--color-button-text)',
                fontSize: Responsive.isMobileView() ? '1rem' : '1.5rem',
                boxShadow: 'none',
            }),
            menuPortal: (provided, state) => ({
                ...provided,
                backgroundColor: 'var(--color-button)',
            }),

            noOptionsMessage: (provided, state) => ({
                ...provided,
                color: 'var(--color-button-text)',
                fontSize: Responsive.isMobileView() ? '1rem' : '1.5rem',
            }),
        }}
        menuIsOpen={menuIsOpen ?? true}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isMulti
        onChange={(newValues: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => {
            if (isMulti && onItemsSelected) {
                onItemsSelected(newValues);
            } else if (onItemSelected) {
                onItemSelected(newValues);
            }
        }}
        noOptionsMessage={({ inputValue }) => {
            return noOptionsMessage;
        }}
        isSearchable
        hideSelectedOptions={true}
        controlShouldRenderValue={false}
        options={items}
    />;
};

export const SimpleComboBox = (props: ComboBoxProps) => {
    const { items, placeholder, onItemsSelected, onItemSelected, noOptionsMessage, defaultValue, className, isOptionSelected, isMulti = true } = props;
    return <Select
        className={className}
        isOptionSelected={isOptionSelected}
        components={{
            DropdownIndicator: () => null, IndicatorSeparator: () => null
        }}
        defaultValue={defaultValue}
        value={defaultValue}
        placeholder={placeholder}
        isMulti={isMulti}
        onChange={(newValues: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => {
            if (isMulti && onItemsSelected) {
                onItemsSelected(newValues);
            } else if (onItemSelected) {
                onItemSelected(newValues);
            }
        }}
        noOptionsMessage={({ inputValue }) => {
            return noOptionsMessage;
        }}
        isSearchable
        hideSelectedOptions={true}
        controlShouldRenderValue={false}
        options={items}
    />;
};
