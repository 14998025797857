'use client';

import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import {ChevronDown} from 'lucide-react';
import {cn} from '../utils';


const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({className, ...props}, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={cn("border-b", className)}
        {...props}
    />
))
AccordionItem.displayName = "AccordionItem"

const BackOfficeAccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({className, ...props}, ref) => {
    return <AccordionPrimitive.Item
        ref={ref}
        className={cn('border-2 border-wikotiAction pl-2 mt-2 bg-wikotiBackground shadow rounded-2xl', className)}
        {...props}
    />;
});

BackOfficeAccordionItem.displayName = 'BackOfficeAccordionItem';


const BackofficeAccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({className, children, ...props}, ref) => (
    <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                'flex flex-1 pl-8 items-center justify-between py-1 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
                className
            )}
            {...props}
        >
            {children}
            <ChevronDown className={cn(
                'h-4 w-4 shrink-0 transition-transform duration-200',
                'text-white h-8 w-8 p-1 ml-auto mr-2 bg-wikotiAction rounded-full'
            )}/>
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
))
BackofficeAccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & { Subtitle?: React.ReactNode }
>(({className, Subtitle, children, ...props}, ref) => (
    <AccordionPrimitive.Header className="flex flex-col">
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                'flex flex-row items-center flex-1 py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
                className
            )}
            {...props}
        >
            {children}
            {/*<ChevronDown className="h-6 w-6 shrink-0 transition-transform duration-200"/>*/}

        </AccordionPrimitive.Trigger>
        {Subtitle}
    </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({className, children, ...props}, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
        {...props}
    >
        <div className={cn("pb-4 pt-0", className)}>{children}</div>
    </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export {
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
    BackofficeAccordionTrigger,
    BackOfficeAccordionItem
};
