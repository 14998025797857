import {AppMenuThemeModel} from '@repo/database';

export type ThemeItem = {
    light: string;
    dark: string;
}

export class Theme {
    fontFamily: string = 'Inter';
    fontFamilyLoader: string = 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap';

    borderColor: ThemeItem = {
        light: '#ffffff',
        dark: '#000000'
    };
    backgroundAccent: ThemeItem = {
        light: '#f3f3f3',
        dark: '#23211c'
    };
    foregroundAccent: ThemeItem = {
        light: '#23211c',
        dark: '#f3f3f3'
    };
    background: ThemeItem = {
        light: '#F3EDF3',
        dark: '#23211c'
    };
    foreground: ThemeItem = {
        light: '#23211c',
        dark: '#fff2ca'
    };
    button: ThemeItem = {
        light: '#FF8753',
        dark: '#FF8753'
    };
    buttonText: ThemeItem = {
        light: '#33032F',
        dark: '#33032F'
    };
    dangerButton: ThemeItem = {
        light: '#FF0000',
        dark: '#FF0000'
    };
    dangerButtonText: ThemeItem = {
        light: '#ffffff',
        dark: '#ffffff'
    };
    disabledButton: ThemeItem = {
        light: '#c2c2c2',
        dark: '#c2c2c2'
    };
    disabledButtonText: ThemeItem = {
        light: '#000000',
        dark: '#000000'
    };

    constructor(light?: AppMenuThemeModel, dark?: AppMenuThemeModel) {
        if (light) {
            this.setTheme(light, 'light');
        }
        if (dark) {
            this.setTheme(dark, 'dark');
        }
    }

    public getMap: () => Record<string, string> = () => {
        return {
            '--color-border': this.hashToRgb(this.borderColor.light).join(' '),
            '--color-border-dark': this.hashToRgb(this.borderColor.dark).join(' '),
            '--color-background': this.hashToRgb(this.background.light).join(' '),
            '--color-background-accent': this.hashToRgb(this.backgroundAccent.light).join(' '),
            '--color-foreground-accent': this.hashToRgb(this.foregroundAccent.light).join(' '),
            '--color-background-dark': this.hashToRgb(this.background.dark).join(' '),
            '--color-foreground': this.hashToRgb(this.foreground.light).join(' '),
            '--color-foreground-dark': this.hashToRgb(this.foreground.dark).join(' '),
            '--color-button': this.hashToRgb(this.button.light).join(' '),
            '--color-button-dark': this.hashToRgb(this.button.dark).join(' '),
            '--color-button-text': this.hashToRgb(this.buttonText.light).join(' '),
            '--color-button-text-dark': this.hashToRgb(this.buttonText.dark).join(' '),
            '--color-danger-button': this.hashToRgb(this.dangerButton.light).join(' '),
            '--color-danger-button-dark': this.hashToRgb(this.dangerButton.dark).join(' '),
            '--color-danger-button-text': this.hashToRgb(this.dangerButtonText.light).join(' '),
            '--color-danger-button-text-dark': this.hashToRgb(this.dangerButtonText.dark).join(' '),
            '--color-disabled-button': this.hashToRgb(this.disabledButton.light).join(' '),
            '--color-disabled-button-dark': this.hashToRgb(this.disabledButton.dark).join(' '),
            '--color-disabled-button-text': this.hashToRgb(this.disabledButtonText.light).join(' '),
            '--color-disabled-button-text-dark': this.hashToRgb(this.disabledButtonText.dark).join(' '),
            'fontFamily': this.fontFamily,
        };
    };

    private setTheme(theme: AppMenuThemeModel, type: 'light' | 'dark') {
        this.background[type] = theme.background?.hex ?? this.background[type];
        this.foreground[type] = theme.foreground?.hex ?? this.foreground[type];
        this.backgroundAccent[type] = theme.backgroundAccent?.hex ?? this.backgroundAccent[type];
        this.foregroundAccent[type] = theme.foregroundAccent?.hex ?? this.foregroundAccent[type];
        this.button[type] = theme.button?.hex ?? this.button[type];
        this.buttonText[type] = theme.buttonText?.hex ?? this.buttonText[type];
        this.dangerButton[type] = theme.dangerButton?.hex ?? this.dangerButton[type];
        this.dangerButtonText[type] = theme.dangerButtonText?.hex ?? this.dangerButtonText[type];
        this.disabledButton[type] = theme.disabledButton?.hex ?? this.disabledButton[type];
        this.disabledButtonText[type] = theme.disabledButtonText?.hex ?? this.disabledButtonText[type];
        this.fontFamily = theme.fontFamily ?? this.fontFamily;
        this.fontFamilyLoader = theme.fontFamilyLoader ?? this.fontFamilyLoader;
    }

    // creates rgb values from hex in the form of [r, g, b]
    private hashToRgb = (hex: string) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return [r ?? 0, g ?? 0, b ?? 0];
    };

    private hashToHsl = (hex: string) => {
        const rgb = this.hashToRgb(hex);
        const r = rgb[0]! / 255;
        const g = rgb[1]! / 255;
        const b = rgb[2]! / 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h = (max + min) / 2;
        let s = (max + min) / 2;
        let l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        h = Math.round(h * 360);
        s = Math.round(s * 100);
        l = Math.round(l * 100);
        return [h, s, l];
    };
}