// serviceWorkerRegistration.js or serviceWorkerRegistration.ts

export const register = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const result = await navigator.serviceWorker
                .register('/push-service-worker.js');
            console.log('[Push Service Worker] Registered');
            return result;
        } catch (e: any) {
            console.log('[Push Service Worker] Failed');
            console.error(e);
            return null;
        }
    }
    return null;
};
